// Change layout variables (with "!default" flag)
// Note: please only adjust Bootstrap variables, or make BS-compatible additions
//$enable-rounded: false !default;

$border-width: 2px !default;
$border-radius-lg: 12px;

@import '@/src/scss/main.scss';

form#CustomerForm-Login {
  small.text-muted a {
    color: $text-muted;
  }
}

.border-info {
  border: 2px solid $info;
  border-radius: 12px;
}

footer {
  a {
    color: $white;
  }
}

div.photo-banner {
  position: relative;
  top: 0;
  left: 0;

  #bottom-layer {
    position: relative;
    top: 0;
    left: 0;
    max-height: 650px;
    min-height: 250px;
    width: 100%;
  }

  #top-layer {
    position: absolute;
    bottom: -2px;
    left: 0;
    max-height: 150px;
    width: 100%;
  }
}

div.social-icons {
  margin-left: 10px;
  display: inline-block;

  a {
    background-color: $info;
    text-align: center;
    padding-top: 5px;
    color: $white;
    font-size: 14px;
    border-radius: 15px;
    align-content: center;
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-right: 5px;

    &:hover {
      background-color: $dark;
    }
  }
}


.top-menu {
  color: $dark;
  font-weight: 600;
  font-size: 16px;

  ul {
    margin-bottom: 0;

    li {
      margin: 10px 10px 10px 10px;
      list-style: none;

      div.social-icons {
        margin-left: 10px;
        display: inline-block;

        a {
          background-color: $dark;
          text-align: center;
          padding-top: 6px;
          color: $white;
          font-size: 11px;
          border-radius: 15px;
          align-content: center;
          display: inline-block;
          width: 25px;
          height: 25px;

          i {
            font-size: 14px;
          }

        }

        :hover {
          background-color: $info;
        }

      }

      a {
        color: $dark;
        text-decoration: none;

      }

      .active {
        a {
          color: $info;
        }
      }
    }
  }
}

.navbar-dark .navbar-nav .nav-link {
  color: $white;
}

.nav-cart {
  @extend .position-relative;
  .nav-link {
    @extend .px-lg-3;
    &.disabled {
      .badge {
        display: none;
      }
    }
  }
  .badge {
    @extend .ml-2, .ml-lg-0;
    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 8px;
      right: 0;
    }
  }
}

small {
  .nav-cart {
    .badge {
      top: -2px !important;
      right: -7px !important;
    }
  }
}
