// Theme variables
$primary: #009ee0;
$secondary: #87ba1e;

//@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
//$font-family-sans-serif: 'PT Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

@import url('https://fonts.googleapis.com/css2?family=Sarabun:wght@400;700&display=swap');

//$black: #00323a !default;

//variables
$info: $primary;
$dark: #06262D;

$primary-faded : lighten($primary, 25%);

$headings-font-family: 'Sarabun', sans-serif;
$headings-color: $primary;

$hamburger-active-layer-color: white;

@import '@/src/scss/preset/deboetzelaer.scss';

$owl-image-path: '~owl.carousel/src/img/';
@import "~owl.carousel/src/scss/owl.carousel";

.flex-wrapper {
  display: flex;
  min-height: 70vh;
  flex-direction: column;
  justify-content: space-between
}

.wrapper {
  margin-bottom: -12rem;
}

.main-headerless {
   @extend .pt-4;
   @extend .mt-4;
 }

.content-wrapper {
  padding: 1.5rem 2rem 12rem 2rem;
  @include media-breakpoint-down(md) {
    padding: 1.5rem 1rem 10rem 1rem;
  }
}

.border-radius-top-1 {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.border-radius-bottom-1 {
  border-bottom-left-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}

.content-news-detail-wrapper {
  img {
    width: 100%;
    height: auto;
  }
}

article {
  border-radius: 15px;
  .border-rad-bottom {
    border: 1px solid $dark;
    border-radius: 0 0 15px 15px;
  }

  .border-rad-top {
    border-radius: 15px 15px 0 0;
  }
}

.dropdown-menu.sub-dropdown.show {
  background: #55add3;
}

.mobile-hamburger-color {
  @extend .text-white;
  @extend .my-3;
}

.navbar {
  padding: 0 8px;
  li.nav-item {
    a {
      padding: 16px 0;
    }
    &.active:after {
      content: '';
      display: block;
      width: 100%;
      border-bottom: 6px solid $primary;
      margin-top: -6px;
      position: relative;
      opacity: 1;
      @include media-breakpoint-down(md) {
        display: none !important;
      }
    }
    &:not(.active):hover:after{
      content: '';
      display: block;
      width: 100%;
      border-bottom: 6px solid $primary-faded;
      margin-top: -6px;
      position: relative;
      opacity: 1;
      @include media-breakpoint-down(md) {
        display: none !important;
      }
    }
  }
  .nav-cart {
    .nav-link {
      @extend .px-1;
    }
  }
}
.content-search {
  max-width: 155px;
  .content-search--form {
    .input-group {
      border: solid gray 1px;
      border-radius: 20px;
    }

    .input-group-text, .form-control {
      background: none;
      @extend .border-0;
      @extend .text-white;
    }
    .form-control {
      border-radius: 0 20px 20px 0;
    }
    .input-group-text {
      @extend .pr-1;
    }
  }
}

.bg-header-bar {
  background: $dark;
}

.share-kop-wrapper p{
  font-weight: 600;
}

figure.img-cover {
  width: 100%;
  height: 450px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @include media-breakpoint-down(md) {
    height: 170px;
  }
  @include media-breakpoint-down(sm) {
    height: 130px;
  }
}

th {
  background: none !important;
  border-top: none !important;
  border-bottom: 2px solid $primary !important;
}

.rounded-img {
  border: none;
  border-radius: 1.25rem;
  margin-bottom: .5rem;
}

.hover--box-shadow {
  text-decoration: none !important;
  img {
    transition: all 0.3s;
  }
  &:hover {
    img {
      box-shadow: 5px 5px 15px 5px #0000000f;
    }
  }
}

//.flavor-shape {
//  @include media-breakpoint-down(md) {
//    height: 30px !important;
//  }
//}

.menu-block {
  border: 2px solid $white;
  border-radius: 15px;
  align-content: center;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 15px;
  transition: all 0.3s;

  &:hover {
    background-color: $dark;
  }

  &.active {
    background-color: $dark;
  }

  a {
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: $white;
    width: 100px;
    height: 100px;

    i.fas {
      display: inline-block;
      width: 100%;
    }

    &:hover {
      text-decoration: none;
    }

  }
}
.space-logo {
  @include media-breakpoint-up(md){
    margin-top: 7rem;
  }
}

@include media-breakpoint-up(md) {
  .position-md-static {
    position: static !important;
  }
}

//.planner-table {
//  @include media-breakpoint-down(md) {
//    th:last-child, td:last-child {
//      display: none !important;
//    }
//  }
//}

.word-break {
  word-break: break-word;
}

.navbar-brand {
  display: block;
  position: absolute;
  left: 170px;
  top: 0;
  @include media-breakpoint-down(sm) {
    right: 0;
    margin: 0;
  }
  img {
    height: 10rem;
    position: absolute;
    top: -40px;
    background: white;
    border-radius: 50%;
    padding: 6px;
    z-index: 999;
    max-width: none;
    @include media-breakpoint-down(lg) {
      height: 6rem;
      left: -9rem;
    }
    @include media-breakpoint-down(md) {
      top: 10px;
      z-index: 99;
      left: 59vw;
    }
    @include media-breakpoint-down(sm) {
      right: 0;
      left: auto;
      margin: 0;
    }
  }
}

.home h2#rooster-header {
  text-align: center;
}

#form-container {
  margin-left: inherit !important;
  margin-right: inherit !important;
}

.home {
  form#rooster-form div.form-group {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  #form-container {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  h2#rooster-header {
    color: $info;
    text-align: center;
  }
}

footer {
  .social-icons {
    margin: 0;
  }
}

#invoices div.card-header {
  background-color: $info;
  color: $white;
  border-radius: 0;
}

#invoices div.card-footer {
  background-color: $white;
  border-top: 1px solid $black;
  border-bottom: 1px solid $black;
  border-radius: 0;
}


#invoices div.card-header span.js-resend-invoice {
  color: $white !important;
}

@include media-breakpoint-up(sm) {
  #side-background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0px;
    z-index: -1;
  }

  #side-menu-bg {
    height: 100vh;
    background-color: $info;
  }

  .navbar {
    z-index: 2;
  }

  #topNav {
    z-index: 2;
    background-color: $white;
  }
}

#topNav li.active a{
  color: $info;
}

#footer-img {
  z-index: -2;
  position: relative;
}

.navbar-nav .dropdown-menu {
  background-color: $primary-faded;
  color: $white;
  border-radius: 0px;
  top: 60px;
  padding: 0;
  border: none;
  a {
    padding: 16px 8px !important;
    color: $white;

    &:hover {
      background-color: $primary;
    }
  }
}

.info-bar--background {
  @extend .bg-dark;
}

.hero-cta {
  z-index: 1;
  background: radial-gradient(circle, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.2) 35%, rgba(0, 0, 0, 0) 100%);
  text-align: center !important;
  .cta-wrapper {
    bottom: 40%;
    @include media-breakpoint-down(md) {
      bottom: 50%;
    }

    .cta-wrapper--inner {
      width: 45%;
      @include media-breakpoint-down(md) {
        width: 90%;
        h2 {
          font-size: 17px;
        }
      }
      @include media-breakpoint-down(sm) {
        h1 {
          display: none;
        }
      }
    }
  }
}

.footer-cta-banners {
  @include media-breakpoint-down(md) {
    display: none !important;
  }
  .footer-cta-item:hover {
    filter: contrast(0.5);
  }
}

.quick-menu-item {
  word-break: break-all;
  &:hover {
    filter: contrast(.5);
  }
}

.sidebar-nav {
  h1,h2,h3,h4,h5,h6 {
    color: $white;
  }
  color: $white;
}

.btn-login {
  background-color: $dark;
}

div#Rooster {
  padding-top: 0rem !important;
}

#footer-bg {
  background: url('../img/golf-donker2.png');
  height: 180px;
  @include media-breakpoint-up(lg) {
    background-size: 100% 100%;
  }
}

footer a:hover {
  color: $white;
}

.sidebar {
  min-width: 500px;
  max-width: 500px;
  background: $primary;
  transition: all .3s;
  position: relative;
  z-index: 1;
  min-height: 90vh;
  @include media-breakpoint-down(md) {
    min-width: 100%;
    min-height: unset;
  }
  .sidebar-nav {
    padding: 0 6rem;
    @include media-breakpoint-down(md) {
      padding: 0 1rem;
    }
    @include media-breakpoint-up(lg) {
      margin-top: 3rem;
    }
  }
}

// Calendar
$space: 1.5rem;

.calendar {
  margin: auto (-$space);
  box-shadow: 0 ($space * .125) ($space * .25) rgba(0, 0, 0, .025);


  .owl-carousel:not(.owl-loaded) {
    //padding: 0 1.5rem;
    > .item:not(:first-child) {
      display: none;
    }
  }

  .owl-carousel .item {
    position: relative;
    z-index: 100;
    -webkit-backface-visibility: hidden;
  }

  li figure {
    padding-top: 0; height: $space * 10;
    border-radius: 12px;
    overflow: hidden;
    background-size: cover;
    .inner {
      position: relative;
      text-align: center; text-shadow: 0 0.063em 0.063em*2 rgba(0,0,0,.5); color: $white;
      h5, .h5 { font-size: 1.5rem; color: white; }
    }
    .inner-wrapper {
      display: flex; align-items: center; justify-content: center;
      z-index: 1;
      height: 100%;
      background: rgba(0,0,0,.5);
      border-radius: 12px;
      overflow: hidden;
    }

    .inner,
    .inner-wrapper { @include transition(); }
  }

  .extrainfo {
    padding: $space * .5;
    a { line-height: $space; }
  }

  li figure:hover {
    .inner-wrapper {
      opacity: 0;
    }
  }
}

.small {
  font-size: 0.8rem;
}

.owl-nav {
  position: absolute;
  top: -2.25rem;
  right: 9px;
  left: 9px;
  font-size: .875rem;
  text-align: right;
  @extend .px-3;
}

.owl-carousel {
  padding: 0 9px 0 9px;
}

.calendar-item {
  border: solid 2px $primary;
  margin-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 12px;
}

.double-spaced {
  height: 58px;
}

.bottom-layer {
  object-fit: cover;
}

.footer-overlap {
  margin-bottom: -8rem;
  padding-bottom: 3rem;
}

.search-btn {
  @include media-breakpoint-down(sm) {
    width: 100%;
    margin-top: 1rem;
  }
}

.btn-grid {
  @extend .btn-dark;
}

.grid-background {
  @extend .bg-dark;
}

.card-body {
  .media-body {
    form {
      display: inline !important;
      button {
        padding-top: 0;
        padding-bottom: 0;
        height: 30px;
        margin-top: .5rem;
      }
    }
  }
}
